<template>
  <!-- SECTION modal-special-services -->
  <b-modal
    id="modal-special-services"
    :title="$t('reservation.specialServices.title')"
    title-class="text-airline font-medium-4 font-weight-bolder mb-0"
    centered
    no-close-on-backdrop
    size="lg"
    @show="handleShowModal"
  >
    <b-card
      body-class="py-1"
    >
      <!-- ANCHOR Itineraries -->
      <div
        class="text-heading-5 mb-25"
      >
        {{ $t('reservation.specialServices.itineraries.title') }}
      </div>
      <b-form-group class="mb-0">
        <template #label>
          <b-form-checkbox
            v-if="bookingData.itineraries.length > 1"
            v-model="itinerariesAllSelected"
            aria-describedby="itineraries"
            aria-controls="itineraries"
            @change="itinerariesToggleAll"
          >
            <div class="text-body-2">
              {{ itinerariesAllSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
            </div>
          </b-form-checkbox>
        </template>
        <template v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            v-model="itinerariesSelected"
            :aria-describedby="ariaDescribedby"
            stacked
            name="itineraries"
            class="mb-1"
          >
            <div
              v-for="(itinerary, indexItinerary) in bookingData.itineraries"
              :key="indexItinerary"
              class="d-flex flex-column"
            >
              <div
                v-for="(segment, indexSegment) in itinerary"
                :key="indexSegment"
              >
                <b-form-checkbox
                  :value="segment.segmentId"
                >
                  <div class="d-flex align-items-center">
                    <div class="mr-50 text-heading-4">
                      {{ convertShortTrip(segment) }}
                    </div>
                  </div>
                </b-form-checkbox>
              </div>
            </div>
          </b-form-checkbox-group>
        </template>
      </b-form-group>

      <!-- ANCHOR Passengers -->
      <div
        class="text-heading-5 mb-25"
      >
        {{ $t('reservation.specialServices.passengers.title') }}
      </div>
      <b-form-group class="mb-0">
        <template #label>
          <b-form-checkbox
            v-if="bookingData.paxLists.length > 1"
            v-model="passengersAllSelected"
            aria-describedby="flights"
            aria-controls="flights"
            @change="passengersToggleAll"
          >
            <div class="text-body-2">
              {{ passengersAllSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
            </div>
          </b-form-checkbox>
        </template>

        <template v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            v-model="passengersSelected"
            :aria-describedby="ariaDescribedby"
            stacked
            name="passengers"
            class="mb-1"
          >
            <div
              v-for="(passenger, indexPassenger) in bookingData.paxLists"
              :key="indexPassenger"
              class="d-flex flex-column my-25"
            >
              <b-form-checkbox
                :value="passenger.paxId"
              >
                <div class="d-flex align-items-center">
                  <div class="mr-50 text-heading-4">
                    {{ `${passenger.paxId} ${passenger.lastName} ${passenger.firstName} ${passenger.title}` }}
                  </div>
                </div>
              </b-form-checkbox>
            </div>
          </b-form-checkbox-group>
        </template>
      </b-form-group>

      <!-- ANCHOR Special services -->
      <div
        class="text-heading-5 mb-25"
      >
        {{ $t('reservation.specialServices.ss.title') }}
      </div>

      <v-select
        v-model="specialServiceSelected"
        :options="specialServicesList"
        :reduce="val => val"
        @input="handleChangeInput"
      >
        <template #selected-option="data">
          <div class="d-flex align-items-center">
            <div class="text-info font-weight-bold">
              {{ data.value }}
            </div>
            <div class="ml-1 text-wrap">
              {{ data.label }}
            </div>
          </div>
        </template>
        <template #option="data">
          <div class="d-flex border-bottom">
            <div :class="`font-weight-bold ${specialServiceSelected && data.value === specialServiceSelected.value ? 'text-white' : 'text-info'}`">
              {{ data.value }}
            </div>
            <div class="ml-1 text-wrap">
              {{ data.label }}
            </div>
          </div>
        </template>
        <template #no-options>
          {{ $t('flight.noOptions') }}
        </template>
      </v-select>

      <!-- ANCHOR FREE TEXT -->
      <div v-if="specialServiceSelected && specialServiceSelected.freeText">
        <div
          class="text-heading-5 my-25"
        >
          {{ $t('reservation.specialServices.ss.freeText') }}
        </div>
        <b-form-input
          v-model="freeText"
          lazy-formatter
          :formatter="removeAccentsUpperCaseFormatter"
          :placeholder="specialServiceSelected.placeholder ? specialServiceSelected.placeholder : ''"
        />
      </div>

      <IAmConfirm
        v-if="!isDisabledSubmit"
        :is-confirm.sync="isConfirm"
      />
    </b-card>

    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient mt-lg-25  border-0"
        :disabled="!isConfirm || isDisabledSubmit"
        pill
        @click="sendSpecialServicesHandle"
      >
        <span class="align-middle">{{ $t('reservation.specialServices.book') }}</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BFormGroup, BCard, BFormCheckboxGroup, BFormCheckbox, BFormInput,
} from 'bootstrap-vue'
import {
  ref, toRefs, watch, computed,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import IAmConfirm from '@/components/IAmConfirm.vue'
// eslint-disable-next-line no-unused-vars
import { convertShortTrip, convertPaxIdToNotIncludesZero, convertISODateTime } from '@/@core/utils/filter'
import { resolveTitle } from '@/constants/selectOptions'

import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BCard,
    vSelect,
    IAmConfirm,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props) {
    const { bookSpecialServices } = useReservationHandle()
    const bookingData = toRefs(props).bookingData
    const specialServicesList = ref([
      { value: 'WCHR', label: 'Xe lăn', freeText: 'PAX SUC KHOE ON DINH CHAN YEU' },
      { value: 'BSCT', label: 'Nôi em bé', freeText: 'INFT 8 MTHS WEIGHT 10KG' },
      { value: 'SPEQ', label: 'Đăng kí bộ GOLF', freeText: 'DIM 130CM X 36CM X20CM WGTH 12KG GOLF SET' },
      // { value: 'OSIVIP', label: 'VIP', freeText: true },
      { value: 'VGML', label: 'Suất ăn chay kiểu phương Tây nghiêm ngặt' },
      { value: 'AVML', label: 'Suất ăn chay theo đạo Hindu' },
      { value: 'VJML', label: 'Suất ăn chay theo đạo Jain' },
      { value: 'VLML', label: 'Suất ăn chay kiểu phương Tây' },
      { value: 'VOML', label: 'Suất ăn chay kiểu phương Đông' },
      { value: 'BBML', label: 'Suất ăn cho trẻ em dưới 2 tuổi' },
      { value: 'CHML', label: 'Suất ăn cho trẻ em từ 2 tuổi đến 5 tuổi' },
      { value: 'BLML', label: 'Suất ăn nhạt, mềm' },
      { value: 'DBML', label: 'Suất ăn cho người mắc bệnh tiểu đường' },
      { value: 'LCML', label: 'Suất ăn ít calo' },
      { value: 'LFML', label: 'Suất ăn ít chất béo' },
      { value: 'LSML', label: 'Suất ăn ít muối' },
      { value: 'NLML', label: 'Suất ăn có ít chất đường có trong sữa' },
      { value: 'FPML', label: 'Suất ăn hoa quả' },
      { value: 'RVML', label: 'Suất ăn chay mà thành phần chủ yếu là hoa quả và rau' },
      { value: 'SFML', label: 'Suất ăn từ hải sản' },
    ])

    const specialServiceSelected = ref()

    const itinerariesSelected = ref([])
    const passengersSelected = ref([])
    const itinerariesAllSelected = ref(false)
    const passengersAllSelected = ref(false)
    const freeText = ref()

    function handleShowModal() {
      itinerariesSelected.value = []
      passengersSelected.value = []
      itinerariesAllSelected.value = false
      passengersAllSelected.value = false
      specialServiceSelected.value = null
      freeText.value = null
    }
    function itinerariesToggleAll(checked) {
      itinerariesSelected.value = checked
        ? bookingData.value.itineraries.flat().map(item => item.segmentId).slice()
        : []
    }
    watch(itinerariesSelected, newValue => {
      if (newValue.length === 0) {
        itinerariesAllSelected.value = false
      } else if (newValue.length === bookingData.value.itineraries.flat().map(item => item.segmentId).length) {
        itinerariesAllSelected.value = true
      } else {
        itinerariesAllSelected.value = false
      }
    })

    function passengersToggleAll(checked) {
      passengersSelected.value = checked
        ? bookingData.value.paxLists.map(item => item.paxId).slice()
        : []
    }
    watch(passengersSelected, newValue => {
      if (newValue.length === 0) {
        passengersAllSelected.value = false
      } else if (newValue.length === bookingData.value.paxLists.map(item => item.paxId).length) {
        passengersAllSelected.value = true
      } else {
        passengersAllSelected.value = false
      }
    })

    function sendSpecialServicesHandle() {
      this.$bvModal.show('modal-api-loading')
      const payload = {
        source: bookingData.value.source,
        agencyCode: bookingData.value.agency,
        paxCode: bookingData.value.paxContact.code,
        pnrNumber: bookingData.value.bookingCode,
        specialServiceRequestUpdates: passengersSelected.value.map(passengerId => {
          const passenger = bookingData.value.paxLists.find(item => item.paxId === passengerId)
          return {
            specialServiceRequest: {
              paxInfo: {
                paxId: passenger.paxId,
                paxType: passenger.paxType,
                gender: passenger.gender || '',
                firstName: passenger.firstName,
                lastName: passenger.lastName,
                title: passenger.title || resolveTitle(passenger.firstName.split(' ')[passenger.firstName.split(' ').length - 1]),
                birthday: passenger.birthday || null,
                parentPaxId: passenger.parentPaxId || '',
                travelFlyer: passenger.travelFlyer || '',
                document: passenger.document || [],
                fareBasisCode: passenger.fareBasisCode || '',
              },
              itineraries: itinerariesSelected.value.map(itinerarySegmentId => {
                // console.log({ itinerarySegmentId })
                const itinerary = bookingData.value.itineraries.flat().find(item => item.segmentId === itinerarySegmentId)
                return {
                  airline: itinerary.airline,
                  departure: itinerary.departure?.iataCode,
                  arrival: itinerary.arrival?.iataCode,
                  departureDate: itinerary.departure ? convertISODateTime(itinerary.departure?.at).dateFilter : '',
                  arrivalDate: itinerary.arrival ? convertISODateTime(itinerary.arrival?.at).dateFilter : '',
                  flightNumber: itinerary.flightNumber || '',
                  segmentId: itinerary.segmentId || '',
                  fareBasisCode: itinerary.fareBasisCode || '',
                  bookingClass: itinerary.bookingClass || '',
                  groupClass: itinerary.groupClass || '',
                  bookingStatus: itinerary.bookingStatus || itinerary.status || '',
                }
              }),
              ssrType: specialServiceSelected.value?.value,
              // text: `4${specialServiceSelected.value?.value}${freeText.value ? `/${freeText.value}` : ''}-${convertPaxIdToNotIncludesZero(passengerId)}`.toUpperCase(),
              text: freeText.value,
            },
            id: '',
            operation: 'ADD',
          }
        }),
      }
      bookSpecialServices(payload)
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
          this.$bvModal.hide('modal-special-services')
        })
    }

    const isConfirm = ref(false)
    const isDisabledSubmit = computed(() => !specialServiceSelected.value || !itinerariesSelected.value.length || !passengersSelected.value.length)

    function handleChangeInput() {
      if (typeof specialServiceSelected.value?.freeText === 'string') {
        freeText.value = specialServiceSelected.value?.freeText
      } else {
        freeText.value = ''
      }
    }
    return {
      sendSpecialServicesHandle,
      specialServicesList,
      specialServiceSelected,
      itinerariesSelected,
      handleShowModal,
      itinerariesToggleAll,
      itinerariesAllSelected,
      convertShortTrip,
      passengersSelected,
      passengersAllSelected,
      passengersToggleAll,
      isConfirm,
      isDisabledSubmit,
      removeAccentsUpperCaseFormatter,
      freeText,
      handleChangeInput,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
